// source: https://github.com/daneden/animate.css

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  transform-origin: top center;
  animation-name: swing;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

@-webkit-keyframes flash {
  0% {
    background-color: Yellow;
    opacity: 1;
  }
  22% {
    background-color: Yellow;
  }
  77% {
    background-color: Red;
  }
  100% {
    background-color: #777;
  }
}

@keyframes flash {
  0% {
    background-color: #e0f2e0;
    opacity: 1;
  }
  22% {
    background-color: #cff7cf;
  }
  77% {
    background-color: #beebbe;
  }
  100% {
    background-color: #b4e0b4;
  }
}

.flash {
  animation-name: flash;
  animation-duration: 1350ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;

  -webkit-animation-name: flash;
  -webkit-animation-duration: 1350ms;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
}
