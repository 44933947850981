.TouchBox {
  position: relative;
  max-width: 200px;
  height: auto;
  word-break: break-all;
  width: 100%;
}

.TouchBox.box-arrow:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  width: 0;
  height: 0;
  border-top: 20px solid $acace-primary-color;
  border-right: 100px solid transparent;
  border-bottom: 0 solid transparent;
  border-left: 100px solid transparent;
  z-index: 101;
}

.TouchBox .arrow-icon {
  display: block;
  margin: -10px auto -20.8px auto;
}

 .TouchBox .arrow-icon.disabled {
  opacity: 0.65;
}

.TouchBox .arrow-icon.success {
  color: #45b649;
}

.TouchBox .arrow-icon.warning {
  color: #ffd700;
}

 .TouchBox .arrow-icon.danger {
  color: #fc5c7d;
}

 .TouchBox .arrow-icon.primary {
  color: #6a82fb;
}

 .TouchBox .arrow-icon.secondary {
  color: #fc5c7d;
}

.TouchBox button {
  width: 100%;
}




.TouchBox .mbottom {
  margin-bottom: 0.2rem;
}

.TouchBox .absolute {
  position: absolute;
  top: 0;
  right: 0;
}

.TouchBox .absolute.weigh {
  right: -5%;
}

.TouchBox .step-modal-button {
  width: 40px;
  height: 35px;
}

.TouchBox .absolute.weigh-badge {
  font-size: 100% !important;
}

// .TouchBox .touchbox-button:disabled {
//   background-color: #dbd8c6;
//   border-color: #dbd8c6;
// }

.arrow_box {
  position: relative;
  background: #88b7d5;
  border: 4px solid #c2e1f5;
}
.arrow_box:after,
.arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #88b7d5;
  border-width: 30px;
  margin-left: -30px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #c2e1f5;
  border-width: 36px;
  margin-left: -36px;
}
